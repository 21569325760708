import React from "react";
import Recaptcha from 'react-recaptcha';
import { useStaticQuery, graphql } from 'gatsby';
import { useEffect, useState } from 'react';
import { Typography, Grid, Box, TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { Helmet } from "react-helmet";

interface ContactFormProps {
    title?: string;
    cta: string;
    message?: string;
}

const ContactForm: React.FC<ContactFormProps> = (props) => {
    const [submission, setSubmission] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [comments, setComments] = useState("");
    const [commentsError, setCommentsError] = useState("");
    const [success, setSuccess] = useState(false);
    const [subscribe, setSubscribe] = useState(false);
    const [recapcha, setVerified] = useState(false);
    const [recapchaError, setRecapchaError] = useState("");

    const { site } = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                recaptchaKey
            }
        }
    }
    `);

    useEffect(() => {
        window['_show_thank_you'] = (id, message, cmpUrl) => {
            setSuccess(true);

            if (cmpUrl) {
                setSubmission(cmpUrl);
            }
        }
    });

    const defaultFormElements = {
        u: 44,
        f: 44,
        c: 0,
        m: 0,
        s: "",
        act: "sub",
        v: 2
    };

    const validateAndSubmit = (e) => {

        if (!name) {
            setNameError("Your Name Is Required");
            return;
        } else {
            setNameError("");
        }

        if (!email) {
            setEmailError("Your Email Is Required");
            return;
        } else if (!email.match(/^[\+_a-z0-9-'&=]+(\.[\+_a-z0-9-']+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i)) {
            setEmailError("Email Address Is Not Valid");
            return;
        } else {
            setEmailError("");
        }

        if (!comments) {
            setCommentsError("Please enter a message");
            return;
        } else {
            setCommentsError("");
        }

        // if(!recapcha) {
        //     setRecapchaError("Please complete the reCaptcha below.");
        //     return;
        // } else {
        //     setRecapchaError("");
        // }

        var serialized = Object.entries(defaultFormElements).map(kvp => `${kvp[0]}=${encodeURIComponent(kvp[1])}`)
            .concat([
                `fullname=${encodeURIComponent(name)}`,
                `email=${encodeURIComponent(email)}`,
                `field[22]=${encodeURIComponent(comments)}`,
                `field[23][]=~%7C&field[23][]=${subscribe ? 'Subscribe' : ''}`
            ]).join("&");

        var submit = `https://teamsherzai80699.activehosted.com/proc.php?${serialized}&jsonp=true`;

        setSubmission(submit);
    };

    const verifyCallback = (response) => {
        setVerified(true);
    }
    
    if(props.message && !comments){
        setComments(props.message);
    }

    return (
        <>
            {submission && <Helmet>
                <script src={submission}></script>
            </Helmet>}

            {props.title && <Typography variant="h3" align="center" gutterBottom>{props.title}</Typography>}
            <Grid container justify="center" alignItems="center">
                <Grid item container xs={12} justify="center">
                    <Box flexGrow={1}
                        maxWidth={450} px={2} pb={3}>
                        <TextField fullWidth name="name" id="name" required autoComplete="name" error={nameError != ""} label={nameError ? nameError : "Name"} value={name} onChange={(e) => setName(e.target.value)} />
                    </Box>
                </Grid>
                <Grid item container xs={12} justify="center">
                    <Box flexGrow={1}
                        maxWidth={450} px={2} pb={3}>
                        <TextField fullWidth name="email" id="email" required type="email" autoComplete="email" error={emailError != ""} label={emailError ? emailError : "Email"} value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Box>
                </Grid>
                {!props.message && 
                <Grid item container xs={12} justify="center">
                    <Box flexGrow={1}
                        maxWidth={450} px={2} pb={2}>
                        <TextField label={commentsError ? commentsError : "What would you like to discuss?"}
                            
                             error={commentsError !=""} fullWidth id="comments" required multiline rows="6" variant="outlined"
                        value={comments} onChange={(e) => setComments(e.target.value)} />
                    </Box>
                </Grid>}
                <Grid item container xs={12} justify="center">
                    <Box flexGrow={1}
                        maxWidth={450} px={2} pb={2}>
                        <FormControlLabel
                            control={
                        <Checkbox value={subscribe} onChange={(e) => setSubscribe(e.target.checked)} />}
                        label="Get The Latest Team Sherzai News" />
                    </Box>
                </Grid>
                <Grid xs={12} item container justify="center">
                    <Box pb={2}>
                        {recapchaError && <Typography variant="body1" color="error">{recapchaError}</Typography>}
                        <Recaptcha
                            sitekey={site.siteMetadata.recaptchaKey}
                            verifyCallback={verifyCallback}
                            render="explicit" />
                    </Box>
                </Grid>
                <Grid item xs={12} container justify="center">
                    {!success && <Button disabled={submission != ""} variant="contained" color="secondary" onClick={validateAndSubmit}>{props.cta}</Button>}
                    {success && <Box px={4}><Typography variant="body1">We will be in touch!</Typography></Box>}
                </Grid>
            </Grid>
        </>
    );
}

export default ContactForm;